import { PLANNING_PANEL_ID, REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';

const alertSubscription = {
	id: 'alertSubscription',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.alertSubscription',
	functionality: 'CREATE_SUBSCRIPTION', // set the functionality if needed
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-alertSubscription-' + model.name + '-show', row);
	}
};
/*const validateStopRules = {
	id: 'validateStopRules',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.validateStopRules',
	functionality: 'READ_VALIDATESTOPRULES',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-validateStopRules-' + model.name + '-show', row);
	}
};*/

const authorizeStop = {
	id: 'authorizeStop',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.authorizeStop',
	functionality: 'EDIT_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return (
			registries.length > 0 &&
			(registries[0].statusid == 'PA' || registries[0].statusid == 'PL' || registries[0].statusid == 'RQ' || registries[0].statusid == 'AC')
		);
	},
	runAction: function (action, model, registries) {
		let row = registries;

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-authorizeStop-' + model.name + '-show', row);
	}
};

const authorizeStops = {
	id: 'authorizeStops',
	selectionType: 'multiple', // [single|multiple|general]
	label: 'actions.stop.authorizeStops',
	functionality: 'EDIT_STOP_HIDE', // set the functionality if needed
	checkAvailability: function (registries) {
		return (
			registries && (registries.statusid == 'PA' || registries.statusid == 'PL' || registries.statusid == 'RQ' || registries.statusid == 'AC')
		);
	},
	runAction: function (action, model, registries) {
		let row = registries;

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-authorizeStops-' + model.name + '-show', row);
	}
};

const planningStopsMap = {
	id: 'planningStopsMap',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.planningStopsMap',
	functionality: 'ACTION_PLAN_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return (
			registries.length > 0 &&
			(registries[0].statusid == 'PA' || registries[0].statusid == 'PL' || registries[0].statusid == 'RQ' || registries[0].statusid == 'AC')
		);
	},
	runAction: function (action, model, registries) {
		if (!registries[0].thegeom) {
			this.$puiNotify.error(`${this.$t('shipsituation.resquesteds.geomnull')}`);
			this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
			return;
		}

		if ((berthstop && berthstop[0].thegeom == null) || berthstop[0].thegeom == '') {
			// El muelle solicitado no tiene geometría
			this.$puiNotify.error(`${this.$t('shipsituation.resquesteds.geomnull')}`);
			this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
			return;
		} else {
			const data = JSON.stringify({ id: registries[0].id, fecini: registries[0].eta, fecend: registries[0].etd });

			// Codifica la cadena JSON en base64
			const base64String = btoa(data);
			// Update panel active to past panel
			this.$store.commit('setActivePanelPeriods', { panelId: PLANNING_PANEL_ID, instantPanel: PLANNING_PANEL_ID });
			this.$store.commit('setPanelParentActive', { panelId: PLANNING_PANEL_ID });
			// Go to route
			this.$router.push('/shipsituation/planningselect/' + base64String);
		}
	}
};

const authorizetopsMap = {
	id: 'authorizeStopsMap',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.authorizeStopsMap',
	functionality: 'EDIT_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return (
			registries.length > 0 &&
			(registries[0].statusid == 'PA' || registries[0].statusid == 'PL' || registries[0].statusid == 'RQ' || registries[0].statusid == 'AC')
		);
	},
	runAction: function (action, model, registries) {
		// Check if berth is null
		const portId = window.localStorage.getItem('workingPortsId');
		const berths = JSON.parse(window.localStorage.getItem(`cartoberths-${portId}`));
		const berthstop = berths.filter((berth) => {
			return berth.id === registries[0].locationid;
		});
		if (!registries[0].thegeom) {
			this.$puiNotify.error(`${this.$t('shipsituation.resquesteds.geomnull')}`);
			this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
			return;
		}
		if ((berthstop && berthstop[0].thegeom == null) || berthstop[0].thegeom == '') {
			// El muelle solicitado no tiene geometría
			this.$puiNotify.error(`${this.$t('shipsituation.resquesteds.geomnull')}`);
			this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
			return;
		} else {
			const data = JSON.stringify({ id: registries[0].id, fecini: registries[0].eta, fecend: registries[0].etd });
			// Codifica la cadena JSON en base64
			const base64String = btoa(data);
			// Update panel active to past panel
			this.$store.commit('setActivePanelPeriods', { panelId: REQUESTEDS_PANEL_ID, instantPanel: REQUESTEDS_PANEL_ID });
			this.$store.commit('setPanelParentActive', { panelId: REQUESTEDS_PANEL_ID });
			// Go to route
			this.$router.push('/shipsituation/authorizeselect/' + base64String);
		}
	}
};

const denyStops = {
	id: 'denyStops',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.denyStops',
	functionality: 'ACTION_DENY_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return registries[0] && registries[0].statusid == 'AC';
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		//row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-denyStops-' + model.name + '-show', row);
	}
};

const initiateStop = {
	id: 'initiateStop',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.initiateStop',
	functionality: 'ACTION_INITIATE_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return registries[0] && registries[0].statusid == 'AU';
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		//row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-initiateStop-' + model.name + '-show', row);
	}
};

const updateActualStop = {
	id: 'updateActualStop',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.updateActualStop',
	functionality: 'ACTION_UPDATE_ACTUAL_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		const editableStatus = ['IN', 'FI'];
		if (registries.length > 0) {
			const isEditable = editableStatus.some((element) => element === registries[0].statusid);

			if (registries[0] && isEditable) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		//row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-updateActualStop-' + model.name + '-show', row);
	}
};

const finishStop = {
	id: 'finishStop',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.stop.finishStop',
	functionality: 'ACTION_FINISH_STOP', // set the functionality if needed
	checkAvailability: function (registries) {
		return registries[0] && registries[0].statusid == 'IN';
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		//row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-finishStop-' + model.name + '-show', row);
	}
};

const editRequestedBollards = {
	id: 'editRequestedBollards',
	selectionType: 'single',
	label: 'actions.stop.editRequestedBollards',
	functionality: 'ACTION_EDIT_REQUESTED_BOLLARDS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			let order = JSON.parse(window.localStorage.getItem('workingStopStatus')).filter((status) => status.stopstatuscode == 'IN')[0].orderby;
			return registries[0].orderstatus < order;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-editRequestedBollards-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [
		alertSubscription,
		authorizeStop,
		authorizeStops,
		planningStopsMap,
		authorizetopsMap,
		denyStops,
		editRequestedBollards,
		initiateStop,
		updateActualStop,
		finishStop
	],
	formactions: []
};
